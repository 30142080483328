.menu {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;

  align-items: center;
}

.menu::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  opacity: 0.9;
  background: linear-gradient(0deg, #464d69 0%, rgb(70, 77, 105) 100%);
}

.logo {
  z-index: 1;

  width: 59px;
  margin-top: 6px;
  margin-bottom: 26px;
}

.group {
  font-size: 11px;
  line-height: 20px;

  z-index: 1;

  width: 100%;
  padding: 8px 23px;

  color: #fff;

  @media screen and (max-width: 1700px) {
    line-height: 19px;
  }
}

.button {
  width: 100%;
}

.link {
  font-size: 17px;

  display: flex;

  width: 100%;
  height: 56px;
  padding: 0 24px;

  color: #fff;

  align-items: center;
  gap: 15px;

  @media screen and (max-width: 1700px) {
    font-size: 15px;

    height: 53px;
    padding: 0 23px;
  }
}

.link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.04);
}

.link.link_selected {
  background-color: rgba(0, 0, 0, 0.2);
}
