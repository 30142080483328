.draggable {
  font-size: 14px;
  line-height: 22px;

  margin: 2px 4px 2px 0;
  padding-right: 0;
  padding-left: 8px;

  cursor: grab;

  background-color: rgba(0, 0, 0, 0.06);

  :global(.ant-tag-close-icon) {
    display: inline-flex;

    height: 22px;
    margin: 0;
    padding-right: 5px;
    padding-left: 3px;

    align-items: center;
  }
}

.dragging {
  background-color: rgba(0, 0, 0, 0.4);
}
