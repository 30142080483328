.container {
  overflow: hidden;

  height: 100vh;
}

.sidebar {
  overflow-y: auto;

  height: 100vh;

  background-image: url(../../../assets/images/sidebarBackground.jpg) !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
}

.sidebar:not(:global(.ant-layout-sider-collapsed)) {
  width: 260px !important;
  min-width: 260px !important;
  max-width: 260px !important;

  flex-basis: 260px !important;

  @media screen and (max-width: 1700px) {
    width: 244px !important;
    min-width: 244px !important;
    max-width: 244px !important;

    flex-basis: 244px !important;
  }
}

.layout {
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 50px;
}

.sidebarToggle {
  font-size: 20px;

  flex-shrink: 0;
}

.title {
  white-space: nowrap;
}

.content {
  padding: 20px;

  flex-shrink: 0;
}

.logOutContainer {
  text-align: end;

  flex-grow: 1;
}

.logOut {
  font-size: 15px;

  color: black;
}

.logOut:hover {
  text-decoration: underline;

  color: black;
}
