.table {
  :global(.ant-table-column-sorter) {
    width: 12px;
    margin-left: 10px;
  }

  :global(.ant-dropdown-trigger) {
    width: 20px;
  }
}

.cell {
  display: block;

  text-align: center;
  white-space: nowrap;
}

.cell_title {
  user-select: none;
}

.filterWrapper {
  width: 206px;
  padding: 8px;
}
