.container {
  display: flex;

  height: 100vh;

  background-image: url(../../assets/images/loginBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 831px;
  height: fit-content;
  margin-top: 55px;
  padding: 32px 100px;

  border-radius: 5px;
  background-color: #fff;

  align-items: center;
}

.logo {
  width: 70px;
  margin-bottom: 30px;
}

.formItem {
  width: 100%;
  margin-bottom: 25px;
}

.input {
  font-size: 16px;

  width: 100%;
  height: 52px;
  padding: 0 25px;

  color: #464d69;
  border-color: #ebedf2;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.input::placeholder {
  font-size: 16px;

  color: #727891;
}

.button {
  font-size: 15px;

  width: 100%;
  height: 41px;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  color: #fff;
  border-radius: 4px;
  background-color: #5d92f4;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.button:hover {
  background-color: rgb(65, 102, 170) !important;
}

@media screen and (max-width: 768px) {
  .form {
    margin: 0 12px;
    padding: 28px 26px;
  }

  .logo {
    margin-bottom: 26px;
  }

  .formItem {
    margin-bottom: 23px;
  }

  .input {
    font-size: 14px;

    padding: 0 22px;
  }

  .input::placeholder {
    font-size: 14px;
  }
}
