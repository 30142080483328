.button {
  position: relative;

  overflow: hidden;

  padding: 0;

  cursor: pointer;

  border: none;
  background: none;
}

.circle {
  position: absolute;

  transform: scale(0);
  animation: ripple 0.4s linear;

  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
}

@keyframes ripple {
  to {
    transform: scale(1.5);

    opacity: 0;
  }
}
